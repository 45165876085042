import { useContext } from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import Yup from '~/utils/yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Button, Form, Input, Typography } from '@objectedge/pecai-storefront-ds';
import { AuthenticationContext } from '~/components/context/Authentication';
import { useNavigation } from '~/utils/navigation';
import styles from './CustomerLoginForm.module.scss';

const schema = Yup.object().shape({
  email: Yup.string().trim().email('Email inválido').required('Campo é obrigatório'),
  password: Yup.string().trim().required('Campo é obrigatório'),
});

export const CustomerLoginForm = () => {
  const { login, loggingIn } = useContext(AuthenticationContext);
  const { goBack, goTo, getQueryParam } = useNavigation();
  const { handleSubmit, errors, control, setError } = useForm({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ email, password }) => {
    try {
      await login(email, password);
      const back = getQueryParam('back');
      if (back) goTo(back);
      else goBack();
    } catch (e) {
      let errorMessage = 'Senha incorreta.';
      if (e instanceof Error && e.message.includes('disabled temporarily')) {
        errorMessage = `${errorMessage} Tente redefinir a sua senha, clicando no link esqueci minha senha.`;
      }
      setError('password', { type: 'custom', message: errorMessage });
    }
  };
  return (
    <div className={styles.loginForm}>
      <div className={clsx(styles.or, 'd-flex d-md-none mb-4')}>
        <hr />
        <Typography variant="body-text-1">OU</Typography>
        <hr />
      </div>

      <Typography variant="display-3" className="mb-4">
        Já sou cadastrado no Peça aí
      </Typography>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          as={Input}
          name="email"
          label="E-mail"
          className="mb-2"
          status={errors.email ? 'danger' : 'default'}
          helperText={errors.email?.message ?? ''}
          data-qa="login-email"
        />
        <Controller
          control={control}
          as={Input}
          name="password"
          type="password"
          label="Senha"
          className="mb-4"
          status={errors.password ? 'danger' : 'default'}
          helperText={errors.password?.message ?? ''}
          data-qa="login-password"
        />
        <Link href="/login/esqueci-minha-senha">
          <a className="d-block mb-4" data-qa="forgot-password">
            Esqueci minha senha
          </a>
        </Link>
        <Button type="submit" block disabled={loggingIn} data-qa="login-button">
          Entrar
        </Button>
      </Form>
    </div>
  );
};

export default CustomerLoginForm;
