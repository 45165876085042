import { Footer, Header } from '~/components/structure';
import styles from './LoginLayout.module.scss';

interface LoginLayoutProps {}

export const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => {
  return (
    <>
      <Header minimalist stepper />
      <main className={styles.loginLayout}>{children}</main>
      <Footer />
    </>
  );
};

export default LoginLayout;
