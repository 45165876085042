import clsx from 'clsx';
import { Button, Typography } from '@objectedge/pecai-storefront-ds';
import styles from './CustomerNewAccountForm.module.scss';
import { useLoginAndRegisterModal } from '~/utils/useLoginAndRegisterModal';

export const CustomerNewAccountForm = () => {
  const { handleGoToRegisterModal } = useLoginAndRegisterModal();

  return (
    <div className={styles.loginForm}>
      <Typography variant="display-3" className="mb-4">
        Quero me cadastrar no Peça aí
      </Typography>

      <div className={clsx('d-none d-md-block', 'd-block')}>
        <Button onClick={() => handleGoToRegisterModal()} block data-qa="register-button">
          Continuar
        </Button>
      </div>
    </div>
  );
};

export default CustomerNewAccountForm;
