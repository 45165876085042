import cookie from 'cookie';
import Head from 'next/head';
import { useContext, useEffect } from 'react';

import { Col, Row } from '@objectedge/pecai-storefront-ds';
import { useRouter } from 'next/router';
import { AuthenticationContext } from '~/components/context/Authentication';
import { CustomerLoginForm } from '~/components/forms/CustomerLoginForm';
import CustomerNewAccountForm from '~/components/forms/CustomerNewAccountForm';
import LoginLayout from '~/layouts/LoginLayout';
import { Page, useNavigation } from '~/utils/navigation';

import styles from './Login.module.scss';

const Login = ({ encodedLoginInfo }) => {
  const { setToken, isLoggedIn } = useContext(AuthenticationContext);
  const { goTo, goToPage, getQueryParam } = useNavigation();
  const router = useRouter();

  // Hook to check if login has received the encoded Login information from query parameters
  useEffect(() => {
    if (isLoggedIn) {
      router.back();
      return;
    }
    if (encodedLoginInfo) {
      try {
        const decodedLoginInfo = JSON.parse(Buffer.from(encodedLoginInfo, 'base64').toString());
        const back = getQueryParam('callback');
        if (decodedLoginInfo?.hasOwnProperty('t')) {
          setToken(decodedLoginInfo.t);
        }

        // if (decodedLoginInfo?.hasOwnProperty('p')) {
        //   setProviderId(decodedLoginInfo.p);
        // }

        back ? goTo(back) : goToPage(Page.home);
      } catch (e) {
        console.error('Unable to parse token: ', e);
        goToPage(Page.home);
      }
    }
  }, [encodedLoginInfo, isLoggedIn]);
  return (
    <LoginLayout>
      <Head>
        <title>Página de Login - Cliente | Peça aí</title>
        <meta name="title" content="Página de Login - Cliente | Peça aí" />
        <meta
          name="description"
          content={`Faça o login na plataforma da Peça aí ou crie um cadastro novo acessando esta página e preenchendo os seus dados.`}
        />
      </Head>
      <div className="container-fluid container-md">
        <Row>
          <Col md={6} className={styles.loginposition}>
            <CustomerLoginForm />
          </Col>
          <Col md={6} className={styles.registerposition}>
            <CustomerNewAccountForm />
          </Col>
        </Row>
      </div>
    </LoginLayout>
  );
};

export default Login;

export const getServerSideProps = (context) => {
  const COOKIE_KEY = process.env.COOKIE_KEY || 'partnerToken';
  const qParam = context?.query?.q || '';
  const {
    req: {
      url,
      headers: { referer },
    },
    res,
  } = context;
  const refererUrl = referer && new URL(referer);
  const previousUrl = referer ? refererUrl.pathname + refererUrl.search : '/';
  const cookies = cookie.parse(context?.req?.headers?.cookie ?? '');
  const isAuthenticated = !!cookies[COOKIE_KEY as string];

  if (isAuthenticated === true) {
    let redirectTo = previousUrl;
    if (previousUrl.indexOf(Page.login) != -1) {
      redirectTo = Page.home;
    }
    res.setHeader('location', redirectTo);
    res.statusCode = 302;
    res.end();
  }

  if (!!qParam) {
    return { props: { encodedLoginInfo: qParam } };
  } else {
    return { props: { previousUrl } };
  }
};
